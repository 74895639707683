import { richText } from '~/utils/html';
import { routerLinks } from '~/utils/fetch-links';

export default async ({ primary }, { prismic, i18n }) => {
  const table = await prismic.getByUID(
    'module_edito_table',
    primary.table?.uid,
    {
      lang: i18n.localeProperties.value.language,
      fetchLinks: [...routerLinks],
    },
  );

  const {
    title,
    description,
    row_axis_header: rowAxisHeader,
    col_axis_header: colAxisHeader,
    body,
  } = table.data || {};
  const sliceRows = body.map(({ items }) => items);

  return {
    id: primary.table?.uid,
    title: richText(title),
    description: richText(description),
    rowAxisHeader,
    colAxisHeader,
    rows:
      sliceRows?.map((row) =>
        row?.map(
          ({ cell_content: cellContent, cell_indicator: cellIndicator }) => {
            return cellIndicator
              ? Number(cellIndicator)
              : richText(cellContent);
          },
        ),
      ) || [],
  };
};
